import { TimesModel } from './../../shared/models/TimesModel'
import { DayOffWeekOptions } from './../../shared/commons/contants'
import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core'
import { ISelect } from '../../shared/interfaces/ISelect'
import * as _ from 'lodash'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-times',
  templateUrl: './times.component.html',
  styleUrls: ['./times.component.scss']
})
export class TimesComponent implements OnInit, OnChanges {

  @Input() showLabelShift: boolean = true
  @Input() countShifts: number = 1
  @Input() times: TimesModel[] = []

  hoursForm: any
  dayOffWeekOptions: ISelect[]
  shiftsAvailables: Number[] = []

  constructor(
    private toastSrv: ToastrService
  ) {
    this.dayOffWeekOptions = DayOffWeekOptions
    this.populateShiftsAvailables()
  }

  ngOnChanges({
    countShifts,
    times
  }: SimpleChanges = {}): void {

    if (countShifts || times) {
      this.populateShiftsAvailables()
      this.populateHoursForm()
    }

  }

  ngOnInit(): void {

  }

  populateShiftsAvailables() {
    this.shiftsAvailables = []
    for (let index = 1; index <= this.countShifts; index++) {
      this.shiftsAvailables.push(index)
    }
  }

  populateHoursForm() {
    this.hoursForm = []
    for (const dayOfWeek of this.dayOffWeekOptions) {
      const shifts: any[] = []
      const dayOffWeekItem = { dayOfWeek: dayOfWeek._id, shifts }
      for (const shift of this.shiftsAvailables) {
        const saved = this.times.find(time => time.dayOfWeek === dayOfWeek._id && time.shift === shift)
        dayOffWeekItem.shifts.push({ startHour: _.get(saved, 'startHour', ''), endHour: _.get(saved, 'endHour', '') })
      }
      this.hoursForm.push(dayOffWeekItem)
    }
  }

  isValidHour(hour): boolean {
    try {
      const _hour: number[] = hour.split(':')
      if (_hour.length !== 2) return false

      if (_hour[0] > 23 || _hour[0] < 0) return false
      if (_hour[1] > 59 || _hour[1] < 0) return false

      return true
    } catch (error) {
      return false
    }
  }

  public getTimes(): { errors: string[], times: TimesModel[] } {
    const times: TimesModel[] = []
    const errors: string[] = []
    for (const { dayOfWeek, shifts } of this.hoursForm) {
      let shift = 1
      for (const { startHour, endHour } of shifts) {
        if (startHour && endHour) {
          if (!this.isValidHour(startHour)) {
            errors.push(`O horário ${startHour} é inválido`)
            continue
          }
          if (!this.isValidHour(endHour)) {
            errors.push(`O horário ${endHour} é inválido`)
            continue
          }
          times.push({ dayOfWeek, shift, startHour, endHour })
        }
        shift++
      }
    }
    return { errors, times }
  }

}
