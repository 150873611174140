import { ISelect } from './../interfaces/ISelect';
import { ChangePasswordModel } from './../models/ChangePasswordModel';
import { Injectable, Inject, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';
import { IResultHttp } from '../interfaces/IHttpResult';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService<any> {

  constructor(
    @Inject(Injector) injector: Injector
  ) {
    super('user', injector);
  }

  async changePassword(form: ChangePasswordModel) {
    return await this.http.post<IResultHttp<any>>(`${environment.api_url}/user/changepassword`, form).toPromise();
  }
  async getMyCompanys() {
    return await this.http.get<IResultHttp<ISelect[]>>(`${environment.api_url}/user/company/my-companys`).toPromise();
  }
  
}