import { FormatPipe } from './format.pipe';
import { DayOfWeekPipe } from './day-of-week.pipe';
import { RealPipe } from './real.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
  declarations: [
    TruncatePipe,
    RealPipe,
    DayOfWeekPipe,
    FormatPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TruncatePipe,
    RealPipe,
    DayOfWeekPipe,
    FormatPipe
  ]
})
export class PipesModule { }
