import { PrepareHttpQuery } from '../utils/query.utils';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgxCoolDialogsService } from 'ngx-cool-dialogs';
import { Inject, Injector } from '@angular/core';
import * as _ from 'lodash';
import { IQueryOptions } from '../interfaces/IQueryOptions';
import { IResultPaginated } from '../interfaces/IResultPaginated';
import { IResultHttp } from '../interfaces/IHttpResult';
import * as qs from 'qs'


export abstract class BaseService<T> {

  urlBase: string;
  http: HttpClient;
  dialogsSrv: NgxCoolDialogsService;
  toastSrv: ToastrService;
  private urlBaseOriginal: string;

  constructor(
    public url: string,
    @Inject(Injector) injector: Injector
  ) {
    this.urlBase = `${environment.api_url}/${this.url}`;
    this.urlBaseOriginal = `${environment.api_url}/${this.url}`;
    this.http = injector.get(HttpClient);
    this.toastSrv = injector.get(ToastrService);
    this.dialogsSrv = injector.get(NgxCoolDialogsService);
  }

  setParamsFromUrl(fields: string[], values: any[]) {
    this.urlBase = this.urlBaseOriginal;
    fields.forEach((f, i) => {
      this.urlBase = this.urlBase.replace(f, values[i]);
    });
  }

  public async GetAll(options?: IQueryOptions): Promise<IResultPaginated<T>> {
    const { success, data } = await
      this.http.get<IResultHttp<IResultPaginated<T>>>(`${this.urlBase}${PrepareHttpQuery(options)}`).toPromise();
    if (success) {
      return data;
    } else {
      return undefined;
    }
  }

  public async GetById(id: string, query?: any): Promise<IResultHttp<T>> {
    const result = await this.http.get(`${this.urlBase}/${id}${`?${qs.stringify(query || {})}`}`).toPromise();
    return result as IResultHttp<T>;
  }

  public post(model: T): Promise<IResultHttp<T>> {
    return this.http.post(this.urlBase, model).toPromise() as Promise<IResultHttp<T>>;
  }

  public put(model: T, id: string): Promise<IResultHttp<T>> {
    return this.http.put(`${this.urlBase}/${id}`, model).toPromise() as Promise<IResultHttp<T>>;
  }

  public save(model: T, id?: string): Promise<IResultHttp<T>> {
    if (id)
      return this.put(model, id);
    else
      return this.post(model);
  }

  public async delete(model: T, options?: { message?: string, field?: string, useConfirm?: boolean }) {
    const message = _.get(options, 'message');
    const field: string = _.get(options, 'field');
    const useConfirm: boolean = _.get(options, 'useConfirm', true);

    return new Promise(async (resolve, reject) => {
      if (useConfirm) {
        this.dialogsSrv.confirm(message || `Deseja realmente excluir o(a) ${model[field ? field : 'name']}?`)
          .subscribe(async rest => {
            if (rest) {
              try {
                const result = await this.http.delete(`${this.urlBase}/${model['_id']}`).toPromise();
                setTimeout(() => {
                  this.toastSrv.show('Registro excluído com sucesso', 'Sucesso');
                }, 300);
                resolve(result['success']);
              } catch (error) {
                reject(error);
                console.error(`delete-${this.url}-${model['_id']}-reason:`, error);
              }
            } else resolve(rest);
          }, () => { resolve(false) })
      } else {
        try {
          const result = await this.http.delete(`${this.urlBase}/${model['_id']}`).toPromise();
          resolve(result['success']);
        } catch (error) {
          reject(error);
          console.error(`delete-${this.url}-${model['_id']}-reason:`, error);
        }
      }
    });
  }
}