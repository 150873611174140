import { HttpRequestInterceptorModule } from './shared/interceptors/http-request.interceptor.module';
import { LoadingService } from './shared/services/loading.service';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { RootGuardService } from './shared/services/root-guard.service';
import { AuthService } from './shared/services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { NgxCoolDialogsModule, NgxCoolDialogsService } from 'ngx-cool-dialogs';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    HttpRequestInterceptorModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
    }),
    NgxCoolDialogsModule.forRoot({
      theme: 'default', // available themes: 'default' | 'material' | 'dark'
      okButtonText: 'Sim',
      cancelButtonText: 'Não',
      color: '#c70039',
      titles: {
        alert: 'Atenção!',
        confirm: 'Confirma?'
      }
    })
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent
  ],
  providers: [
    AuthService,
    AuthGuardService,
    RootGuardService,
    LoadingService,
    NgxCoolDialogsService,
    {
      provide: JWT_OPTIONS,
      useValue: JWT_OPTIONS
    },
    JwtHelperService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
