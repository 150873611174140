import { IResultHttp } from './../interfaces/IHttpResult'
import { IResultPaginated } from './../interfaces/IResultPaginated'
import { Injectable, Inject, Injector } from '@angular/core'
import { BaseService } from './base.service'
import { OrderModel } from '../models/OrderModel'

@Injectable({ providedIn: 'root' })
export class OrderService extends BaseService<OrderModel>{

    constructor(
        @Inject(Injector) injector: Injector
    ) {
        super('order', injector)
    }

    getBoard() {
        return this.http.get<IResultHttp<OrderModel[]>>(`${this.urlBase}/board`).toPromise()
    }

    updateStatus(orderId: string, status: string) {
        return this.http.put<IResultHttp<OrderModel>>(`${this.urlBase}/update-status/${orderId}`, { status }).toPromise()
    }

    async existsPendingOrders() {
        try {
            const { success, data } = await this.http.get<IResultHttp<{ exists: boolean }>>(`${this.urlBase}/exists-pending`).toPromise()
            if (success) return data.exists
            else return false
        } catch (error) {
            return false
        }
    }
}