import { environment } from 'src/environments/environment'
import { CustomerModel } from './../models/CustomerModel'
import { IResultHttp } from './../interfaces/IHttpResult'
import { RegisterCompanyModel } from './../models/RegisterCompanyModel'
import { CompanyModel } from './../models/CompanyModel';
import { Injectable, Inject, Injector } from '@angular/core'
import { BaseService } from './base.service'

@Injectable({ providedIn: 'root' })
export class CompanyService extends BaseService<CompanyModel>{

    constructor(
        @Inject(Injector) injector: Injector
    ) {
        super('company', injector)
    }

    checkSlug(slug: string, companyId: string) {
        return this.http.get<any>(`${this.urlBase}/slug-check/${companyId}?slug=${slug}`).toPromise()
    }

    register(model: RegisterCompanyModel) {
        return this.http.post<IResultHttp<CompanyModel>>(`${this.urlBase}/register`, model).toPromise()
    }

    getMyCustomers() {
        return this.http.get<IResultHttp<CustomerModel[]>>(`${this.urlBase}/my-customers`).toPromise()
    }
    
    getMyCompanys() {
        return this.http.get<IResultHttp<any>>(`${environment.api_url}/user/company/my-companys`).toPromise()
    }
}