import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ISelect } from '../../shared/interfaces/ISelect';

// tslint:disable: no-output-on-prefix
// tslint:disable: no-inferrable-types

@Component({
  selector: 'app-select-data-list',
  templateUrl: './select-data-list.component.html',
  styleUrls: ['./select-data-list.component.scss']
})
export class SelectDataListComponent {

  @Input() data: ISelect[] = [];
  @Input() placeholder: string = 'Selecione';
  @Input() listKey: string = new Date().getTime().toString();
  @Input() disabled: boolean = false;
  @ViewChild('inputDatalist') inputDatalist: ElementRef;
  @Output() onSelect: EventEmitter<string> = new EventEmitter();

  constructor() { }

  onChangeDataListSelect({ target: { value } }) {
    const found = this.data.find(o => o.name === value);
    if (found) {
      this.onSelect.next(found._id);
    } else {
      this.clearhandle();
    }
  }

  clearhandle() {
    this.inputDatalist.nativeElement.value = '';
    this.onSelect.next('');
  }

}
