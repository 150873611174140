import { OrderModelItemComplementItemModel } from './../../shared/models/OrderModelItemComplementItemModel'
import { OrderModel } from './../../shared/models/OrderModel'
import { OrderService } from './../../shared/services/order.service'
import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { OrderModelItemModel } from '../../shared/models/OrderModelItemModel'
import { OrderModelItemComplementModel } from '../../shared/models/OrderModelItemComplementModel'
import * as _ from 'lodash'
@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit {

  order: OrderModel = new OrderModel()
  @Input() orderId: string

  constructor(
    private orderSrv: OrderService,
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.loadOrder(this.orderId)
  }

  async loadOrder(orderId: string) {
    const query = {
      populate: [
        { path: 'customer', select: '_id name phone' },
      ],
      select: '-updatedAt -createdAt -statusEvents -__v'
    }
    const { success, data } = await this.orderSrv.GetById(orderId, query)
    if (success) {
      this.order = data
    }
  }

  getComplements(items: OrderModelItemModel) {
    const complements = []
    _.get(items, 'complements', []).forEach((group: OrderModelItemComplementModel) => {
      _.get(group, 'items', []).forEach((item: OrderModelItemComplementItemModel) => {
        complements.push(`<li>${item.quantity}x ${item.name}</li>`)
      })
    })
    return complements.join('')
  }

  dismiss() {
    this.activeModal.dismiss()
  }

}
