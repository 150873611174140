import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-video-view',
  templateUrl: './video-view.component.html',
  styleUrls: ['./video-view.component.scss']
})
export class VideoViewComponent implements OnInit {

  @Input() title: string
  @Input() videoCode: string

  constructor(
    private activeModal: NgbActiveModal,
    private sanatize: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  dismiss() {
    this.activeModal.dismiss()
  }

  get urlVideo() {
    if (this.videoCode)
      return this.sanatize.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.videoCode}?controls=0`)
    else
      return ''
  }


}
