import { FormatHelperType, FormatHelper } from '../utils/format.utils';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {

  transform(value: any, format: FormatHelperType) {
    return FormatHelper(value, format);
  }

}
