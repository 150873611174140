import { ISelect } from '../interfaces/ISelect'

export const StoreKeys = {
  ACCESS_TOKEN: '@kuru-delivery/ACCESS_TOKEN',
  AVATAR: '@kuru-delivery/AVATAR'
}

export const DayOffWeekOptions: ISelect[] = [
  { _id: 0, name: 'Domingo' },
  { _id: 1, name: 'Segunda-feira' },
  { _id: 2, name: 'Terça-feira' },
  { _id: 3, name: 'Quarta-feira' },
  { _id: 4, name: 'Quinta-feira' },
  { _id: 5, name: 'Sexta-feira' },
  { _id: 6, name: 'Sábado' },
]

export const paymentMethodsTypeI18n = {
  'CREDIT_CARD': 'Cartão de Crédito',
  'DEBIT_CARD': 'Cartão de Débito',
  'VOUCHER': 'Voucher',
  'OTHER': 'Outro',
  'MONEY': 'Dinheiro'
}