import * as qs from 'qs';
import * as _ from 'lodash';
import { IQueryOptions } from '../interfaces/IQueryOptions';

export const PrepareHttpQuery = (options?: IQueryOptions, addQueryPrefix: boolean = true): string => {
  const query = {};
  query['pageSize'] = _.get(options, 'pageSize', 15);
  query['page'] = _.get(options, 'page', 1);
  query['sort'] = _.get(options, 'sort');
  const _search = _.get(options, 'search', {});
  Object.keys(_search).forEach(key =>
    query[key] = _search[key]
  );
  return qs.stringify(query, { addQueryPrefix });
}