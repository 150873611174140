import { GetFullAddress } from './full-address.utils'
import { GetStatusTranslated } from './translate-status.utils'
import * as moment from 'moment';
import { paymentMethodsTypeI18n } from '../commons/contants';

export enum FormatHelperType {
  DOCUMENT = 'document',
  CNPJ = 'cnpj',
  CPF = 'cpf',
  PHONE = 'phone',
  CEP = 'cep',
  MONEY = 'money',
  DATE = 'date',
  DATE_EN = 'date-en',
  STATUS = 'status',
  ADDRESS = 'address',
  PAYMENT_TYPE = 'payment_type'
};
const RemoveMask = (value: string): string => {
  try {
    if (!value) return value;
    value = value.split('.').join('');
    value = value.split('-').join('');
    value = value.split(')').join('');
    value = value.split('(').join('');
    value = value.split('/').join('');
    return value;
  } catch (error) {
    return value;
  }
};
export const FormatHelper = (str: string, type: FormatHelperType) => {
  try {
    if (str) {
      if (type !== FormatHelperType.DATE && type !== FormatHelperType.DATE_EN) str = RemoveMask(str);
      if (type === FormatHelperType.DOCUMENT) {
        if (str.length === 14)
          return str.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5');
        if (str.length === 11)
          return str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
      }
      if (type === FormatHelperType.CNPJ && str.length === 14) {
        return str.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5');
      }
      if (type === FormatHelperType.CPF && str.length === 11) {
        return str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
      }
      if (type === FormatHelperType.PHONE) {
        if (str.length === 11) {
          return str.replace(/(\d{2})(\d{5})(\d{4})/g, '(\$1) \$2-\$3');
        } else if (str.length === 8) {
          return str.replace(/(\d{4})(\d{4})/g, '\$1-\$2');
        } else if (str.length === 9) {
          return str.replace(/(\d{5})(\d{4})/g, '\$1-\$2');
        } else {
          return str.replace(/(\d{2})(\d{4})(\d{4})/g, '(\$1) \$2-\$3');
        }
      }
      if (type === FormatHelperType.CEP) {
        return str.replace(/(\d{5})(\d{3})/g, '\$1-\$2');
      }
      if (type === FormatHelperType.MONEY) {
        return convertAmountLessDecimalToNumber(str);
      }
      if (type === FormatHelperType.DATE) {
        if (!str) return undefined;
        if (str.indexOf('.000Z') > -1) str = str.substr(0, 10)
        return moment(str).format('DD/MM/YYYY');
      }
      if (type === FormatHelperType.DATE_EN) {
        if (!str) return undefined;
        if (str.indexOf('.000Z') > -1) str = str.substr(0, 10)
        return moment(str).format('YYYY-MM-DD');
      }
      if (type === FormatHelperType.STATUS) {
        return GetStatusTranslated(str)
      }
      if (type === FormatHelperType.ADDRESS) {
        return GetFullAddress(str)
      }
      if (type === FormatHelperType.PAYMENT_TYPE) {
        return paymentMethodsTypeI18n[str]
      }
    }
    return str;
  } catch (err) {
    console.error('Error in mask value', err);
    return str;
  }
};

function convertAmountLessDecimalToNumber(amount) {
  const size = amount.toString().length;
  const lessDecimalValue = amount.toString().substr(0, (size - 2));
  const decimalValue = amount.toString().substr((size - 2));
  return parseFloat(`${lessDecimalValue}.${decimalValue}`);
}

