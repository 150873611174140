import { IAuth } from './../interfaces/IAuth'
import { environment } from './../../../environments/environment'
import { StoreKeys } from './../commons/contants'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { JwtHelperService } from '@auth0/angular-jwt'
import { Router } from '@angular/router'
import * as jwtDecode from 'jwt-decode'
import { IResultHttp } from '../interfaces/IHttpResult'
import * as _ from 'lodash'
import { RegisterModel } from '../models/RegisterModel'

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    public http: HttpClient,
    public jwtHelperService: JwtHelperService,
    public router: Router
  ) { }

  async register(model: RegisterModel) {
    return await this.http.post<IResultHttp<any>>(`${environment.api_url}/user/register`, model).toPromise()
  }

  async signIn(mail: string, password: string): Promise<boolean> {
    const { success, data } = await this.http.post<IResultHttp<any>>(`${environment.api_url}/user/login`, { mail, password }).toPromise()
    if (success) {
      localStorage.setItem(StoreKeys.ACCESS_TOKEN, data.token)
      const photo = _.get(this.Auth, 'photo', 'assets/img/anime3.png')
      this.setAvatar = photo
    }
    return success
  }

  async setCurrentCompany(company: string) {
    const { success, data } = await this.http.post<IResultHttp<any>>(`${environment.api_url}/user/company/set-current-company`, { company }).toPromise();
    if (success) {
      localStorage.setItem(StoreKeys.ACCESS_TOKEN, data.token)
      const photo = _.get(this.Auth, 'photo', 'assets/img/anime3.png')
      this.setAvatar = photo
    }
    return success
  }

  async signOut() {
    this.clearTokens()
    this.router.navigate(['/login'])
  }
  clearTokens() {
    localStorage.removeItem(StoreKeys.ACCESS_TOKEN)
  }

  getAccessToken() {
    return localStorage.getItem(StoreKeys.ACCESS_TOKEN)
  }

  getTokenPayload() {
    return jwtDecode(this.getAccessToken())
  }

  async isAuthenticated() {
    const accessToken = this.getAccessToken()
    if (accessToken) {
      const notExpired = !this.jwtHelperService.isTokenExpired(accessToken)
      if (notExpired) {
        return true
      }
    }
    return false
  }

  async forgotPassword(mail: string) {
    return await this.http.post<IResultHttp<any>>(`${environment.api_url}/user/forgotpassword`, { mail }).toPromise()
  }

  get Auth(): IAuth {
    try {
      return this.getTokenPayload() as IAuth
    } catch (error) {
      return {} as IAuth
    }
  }

  get getAvatar(): string {
    return localStorage.getItem(StoreKeys.AVATAR) || 'assets/img/anime3.png'
  }

  set setAvatar(value: string) {
    localStorage.setItem(StoreKeys.AVATAR, value)
  }
}
