import { OrderModelItemModel } from './OrderModelItemModel'

export class OrderModel {
  _id: string
  customer: any
  sequence: string
  status: string
  total: number
  withdraw: boolean
  createdAt: string
  address: string
  items?: OrderModelItemModel[]

  subTotal: number
  deliveryFee: number
  discountCoupon: number
  amount: number
  paymentMethod: any
  needChange: boolean
}


