import { CompanyModel } from './../../shared/models/CompanyModel'
import { CompanyService } from './../../shared/services/company.service'
import * as _ from 'lodash'

import { Component, OnInit } from '@angular/core'

import { IRouteGroup } from '../../shared/interfaces/IRouteGroup'
import { IRouteInfo } from '../../shared/interfaces/IRouteInfo'
import { AuthService } from '../../shared/services/auth.service'

export const ROUTES: IRouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Início',
    icon: 'tim-icons icon-chart-pie-36',
    groupName: ''
  },
  {
    path: '/my-link',
    title: 'Minha Loja',
    icon: 'tim-icons icon-bank',
    groupName: ''
  },
  {
    path: '/board',
    title: 'Board',
    icon: 'fa fa-columns',
    groupName: 'Movimentação',
  },
  {
    path: '/order',
    title: 'Pedidos',
    icon: 'fa fa-list-ul',
    groupName: 'Movimentação',
  },
  {
    path: '/category',
    title: 'Categorias',
    icon: 'fa fa-tags',
    groupName: 'Cadastros',
  },
  {
    path: '/product',
    title: 'Produtos',
    icon: 'fa fa-cube',
    groupName: 'Cadastros',
  },
  {
    path: '/company/layout',
    title: 'Layout',
    icon: 'fa fa-paint-brush',
    groupName: 'Configurações',
  },
  {
    path: '/company/times',
    title: 'Horários',
    icon: 'fa fa-clock',
    groupName: 'Configurações',
  },
  {
    path: '/company/shipping',
    title: 'Entrega',
    icon: 'fa fa-map-marker-alt',
    groupName: 'Configurações',
  },
  {
    path: '/company/payment-method',
    title: 'Pagamento',
    icon: 'fa fa-money-bill-alt',
    groupName: 'Configurações',
  },
  {
    path: '/company',
    title: 'Empresas',
    icon: 'fa fa-building',
    groupName: 'Configurações',
    isNeedRoot: true
  },
  {
    path: '/company/me',
    title: 'Dados da Empresa',
    icon: 'fa fa-building',
    groupName: 'Configurações',
  },
  {
    path: '/user',
    title: 'Usuários',
    icon: 'tim-icons icon-single-02',
    groupName: 'Segurança',
    isNeedRoot: true
  },
]

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItemsGroup: IRouteGroup[] = []
  company: CompanyModel
  constructor(
    private userAuth: AuthService,
    private companySrv: CompanyService
  ) { }

  async ngOnInit() {
    await this.loadCompany()
    const group = _.groupBy(
      !this.userAuth.Auth.isRoot ? ROUTES.filter(x => !x.isNeedRoot) : ROUTES
      , route => route.groupName)

    Object.keys(group).forEach((key, index) => {
      this.menuItemsGroup.push({
        name: key || 'Geral',
        items: _.orderBy(group[key], 'title'),
        open: true
      })
    })
  }
  async loadCompany() {
    const { success, data } = await this.companySrv.GetById('me')
    if (success)
      this.company = data
  }
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false
    }
    return true
  }
}
