import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoViewComponent } from '../video-view/video-view.component';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss']
})
export class HelpButtonComponent implements OnInit {


  @Input() title: string
  @Input() videoCode: string

  constructor(
    private modalSrv: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  openHelpVideo() {
    const modalRef = this.modalSrv.open(VideoViewComponent, {
      size: 'lg',
      windowClass: 'modal-order-viewer'
    })
    modalRef.componentInstance.title = this.title
    modalRef.componentInstance.videoCode = this.videoCode
  }

}
