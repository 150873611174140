import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'real'
})
export class RealPipe implements PipeTransform {
  transform(value: any): string {
    if (isNaN(value)) {
      return 'R$ 0,00';
    }
    const preFormatado = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value).split('$');
    return `R$  ${preFormatado[1]}`;
  }
}