import { SelectDataListComponent } from './select-data-list/select-data-list.component'
import { PipesModule } from './../shared/pipes/pipes.module'
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { InputFileComponent } from './input-file/input-file.component';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { OrderViewComponent } from './order-view/order-view.component';
import { TimesComponent } from './times/times.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { SwitchComponent } from './switch/switch.component';
import { VideoViewComponent } from './video-view/video-view.component';
import { HelpButtonComponent } from './help-button/help-button.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
}


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    PipesModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    InputFileComponent,
    CheckboxComponent,
    OrderViewComponent,
    SelectDataListComponent,
    TimesComponent,
    SwitchComponent,
    VideoViewComponent,
    HelpButtonComponent
  ],
  exports: [
    FooterComponent, 
    NavbarComponent, 
    SidebarComponent, 
    InputFileComponent, 
    CheckboxComponent,
    SelectDataListComponent,
    TimesComponent,
    HelpButtonComponent
  ]
})
export class ComponentsModule { }
