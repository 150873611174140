export const GetStatusTranslated = (status: string) => {
  try {
    const STATUS_TRANSLATE: any = {
      'pending': 'Pendente',
      'preparing': 'Preparando',
      'transport': 'Transporte',
      'ready-withdraw': 'Retirar',
      'done': 'Finalizado',
      'canceled': 'Cancelado',
    }
    return STATUS_TRANSLATE[status]
  } catch (error) {
    return '- - '
  }
}


