import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { AuthService } from '../services/auth.service';
import { LoadingService } from '../services/loading.service';
import { environment } from '../../../environments/environment';
import { IResultHttp } from '../interfaces/IHttpResult';
import { StoreKeys } from '../commons/contants';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private loadingService: LoadingService,
    private toastSrv: ToastrService,
    private router: Router
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<IResultHttp<any>>> {

    this.loadingService.loading(true);

    const accessToken = this.authService.getAccessToken();
    const headers = new HttpHeaders({
      Authorization: accessToken ? `Bearer ${accessToken}` : ''
    });

    req = req.clone({ headers });
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          
          if (event.headers.has('x-refresh-token'))
          localStorage.setItem(StoreKeys.ACCESS_TOKEN, event.headers.get('x-refresh-token'))

          const cloneEvent = event.clone({
            body: {
              success: true,
              data: event.body || [],
              error: undefined
            }
          });
          return cloneEvent;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if ([422, 404, 400].indexOf(error.status) > -1) {
          this.toastSrv.warning(
            _.get(error, 'error.error') ||
            _.get(error, 'error.message') ||
            'Não conseguimos processar sua solicitação',
            'Atenção'
          );
        }
        else if (error.status === 403) {
          this.toastSrv.info('Você não possui permissão de acesso à essa funcionalidade')
        }
        else if (error.status === 401) {
          this.authService.clearTokens()
          this.router.navigateByUrl('/login')
        }
        else if (error.status === 428) {
          this.router.navigateByUrl('/login')
        }

        return throwError({ success: false, data: [{ ...error }], error: error.message });
      }),
      finalize(() => {
        this.loadingService.loading(false);
      })
    );
  }
}


